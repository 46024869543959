const writingContent = [
  {
    title: '🚨 Ticketing Hal: Uber and the question of algorithmic personhood',
    description: 'Thoughts on a longstanding question of mine: if an algorithm speeds behind the wheel, who gets the ticket?',
    url: '/posts/ticketing-hal-uber-and-the-question-of-algorithmic-personhood'
  },
  {
    title: '🔥 The business analytics industry is a dumpster fire',
    description: "Thought leadership on the business analytics industry: maybe we all just shouldn't?",
    url: '/posts/the-business-analytics-industry-is-a-big-dumpster-fire'
  },
  {
    title: '😅 Five free (but not easy) ways to improve your data skills',
    description: "People sometimes ask me how to get started doing data work. Here's how I did it.",
    url: '/posts/5-free-but-not-easy-ways-to-improve-your-data-skills',
  },
  {
    title: '💡 Five questions for biz folks with a good software idea',
    description: 'Before you spend half a million dollars building an app, answer these questions for yourself.',
    url: '/posts/5-questions-for-biz-folks-with-a-good-software-idea',
  },
  {
    title: '🤖 Hey Microsoft, the internet made my not racist, too',
    description: `I built a turing test game called "Bot or Not." It didn't take long before the trolls found it.`,
    url: '/posts/hey-microsoft-the-internet-made-my-bot-racist-too',
   }
]

export default writingContent;
