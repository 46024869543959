import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import './index.scss'
import me from '../images/me.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import projectContent from '../components/projectContent';
import writingContent from '../components/writingContent';
import experienceContent from '../components/experienceContent';
import LinkCard from '../components/LinkCard';
import 'typeface-pacifico';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="splash"><div className="columns p-hero">
      <div className="column">
        <h1 className="hi title is-1">Hi!</h1>
        <h4>I'm <b>Tony Garvan</b>.</h4>
        <p>Long time tinkerer and friendly doofus.</p>
        <p className="navigation"><a href="#projects">Projects</a>
        <a href="#writing">Writing</a>
        <a href="#experience">Experience</a></p>
      </div>
      <div className="column">
        <div className="image-container">
            <img src={me} alt="me" />
        </div>
      </div>
    </div>

    <p className="contacts"><a href="https://twitter.com/anthonygarvan"><FontAwesomeIcon icon={faTwitter} /></a>
    <a href="https://www.linkedin.com/in/anthonygarvan/"><FontAwesomeIcon icon={faLinkedin} /></a>
    <a href="mailto:anthony.garvan@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a></p>

    <div className="projects">
    <h3 id="projects" className="title is-3">Projects</h3>
    {projectContent.map((row, i) => {
      return  <div key={i} className="columns">
            {row.map((project, j) => {
              return <div key={j} className="column">
                  <LinkCard title={project.title} description={project.description} img={project.img} destination={project.destination} />
              </div>
            })}
          </div>
    })}
    </div>

    <p className="more"><a href="https://github.com/anthonygarvan?tab=repositories" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /> More</a></p>
    <hr />
    <div className="writing">
      <h3 id="writing" className="title is-3">Writing</h3>
      {writingContent.map((piece, i) => {
        return <div key={i} className="piece">
            <a href={piece.url} target="_blank"><h5 className="title is-5">{piece.title}</h5></a>
            <p>{piece.description}</p>
        </div>
      })}
    </div>

  <hr />
  <div className="experience">
    <h3 id="experience" className="title is-3">Experience</h3>
    <div className="content">
      <blockquote><em>Far and away the best prize that life has to offer is the chance to work hard at work worth doing.</em></blockquote>
    </div>
    {experienceContent.professional.map((job, i) => {
      return <div key={i} className="experience-entry">
        <div><b>{job.role}</b>&mdash;<a href={job.link}>{job.company}</a></div>
        <div>{job.description}</div>
        <div className="year"><em>{job.years}</em></div>
      </div>
    })}

    <hr />

    <h5 className="title is-5">Education</h5>
    {experienceContent.education.map((ed, i) => {
      return <div key={i} className="experience-entry">
        <div><b>{ed.degree}</b>&mdash;{ed.institution}</div>
        <div>{ed.description}</div>
        <div className="year"><em>{ed.awarded}</em></div>
      </div>
    })}
  </div>

  <p className="more"><a href="/resume.pdf" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /> Full resume</a></p>

  <div className="home">
    <h6 className="title is-6">Tony at home 🏠</h6>
    <p>I live in Hyde Park, Chicago, where I'm raising my two beautiful and energetic boys. When I'm not parenting, working, or coding, 
      I like hanging out on Twitter and going on astrophotography adventures.</p>
  </div>
  </div>
  </Layout>
)

export default IndexPage
