import React from "react"

const LinkCard = (props) => {
  return <div className="p-card"><a href={props.destination} target="_blank">
      <div className="card-image-container">
        <img src={props.img} />
      </div>

      <div className="text-container">
        <div className="p-title">
          {props.title}
        </div>
        <div className="p-description">
          {props.description}
        </div>
      </div>
  </a></div>
}

export default LinkCard;
