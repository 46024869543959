import wordgalaxy from '../images/wordgalaxy.jpg';
import richdotpoordot from '../images/richdotpoordot.png';

const projectContent = [[
  {
    title: 'SheetRocks',
    description: 'A blazing fast, radically expandable spreadsheet.',
    destination: 'https://sheet.rocks',
    img: 'https://sheet.rocks/img/social.png',
  },{
    title: 'RemindUs',
    description: 'Advanced reminders for Slack teams.',
    destination: 'https://remindus.app',
    img: 'https://remindus.app/img/social.png',
  }],
  [{
    title: 'Peeklink',
    description: 'Link sharing with customizable previews.',
    destination: 'https://peek.link',
    img: 'https://peek.link/img/curtains.jpg',
  },{
    title: 'MarkTwo',
    description: 'A seamless, speedy, syncing markdown editor.',
    destination: 'https://marktwo.app',
    img: 'https://marktwo.app/img/social.png',
  }],
  [{
    title: 'Flybox',
    description: 'A streamlined Gmail client.',
    destination: 'https://flybox.app',
    img: 'https://flybox.app/img/social.png',
  }, {
    title: 'WordGalaxy',
    description: 'A semantic visualization of English.',
    destination: 'http://anthonygarvan.github.io/wordgalaxy',
    img: wordgalaxy,
  }]
]

export default projectContent;
