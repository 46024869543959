const experienceContent = {
  professional: [
    {
      role: 'Founder & CEO',
      company: 'SheetRocks, Inc.',
      link: "https://sheet.rocks",
      description: 'I founded a company to build a next generation spreadsheet focused on performance and expressiveness. Wish us luck!',
      years: '2021-present'
    },
    {
      role: 'Engineering manager',
      company: 'Ad Hoc, LLC.',
      link: "https://adhoc.team",
      description: 'I managed a team of over 40 software engineers across 10 teams. We operated over 30 different tools and products that together form the user-facing experience of healthcare.gov.',
      years: '2018-2021'
    },
    {
      role: 'Tech lead',
      company: '18F',
      link: "https://18f.gsa.gov/",
      description: 'I managed a team of 3-6 engineers, lead our data practice area, and contributed to projects for the Federal Election Commission, Social Security Administration, and General Services Administration.',
      years: '2015-2018'
    },
    {
      role: 'Software engineer',
      company: 'ZS Associates',
      link: "https://www.zs.com/",
      description: 'I developed code for a large Enterprise product designed to manage large sales workforces. I also made early contributions to the natural language processing group.',
      years: '2013-2015'
    },
    {
      role: 'CTO',
      company: 'Index companies',
      link: 'https://www.bbb.org/us/ma/boston/profile/insurance-agency/index-companies-llc-0021-127448',
      description: 'I lead development of an algorithm for predicting hurricane landfall on the east coast. Although early results were promising, the startup had to close shop after our office was hit by Hurricane Sandy.',
      years: '2012'
    },
    {
      role: 'Instrumentation engineer',
      company: 'Nanosolar, Inc.',
      link: "https://en.wikipedia.org/wiki/Nanosolar",
      description: 'I started my career building optical and electrical instrumention for monitoring the quality of printed thin film photovoltaics. I learned to code on the job and fell in love with it.',
      years: '2007-2010'
    }
  ],
  education: [
    {
      degree: 'M.S.E., Electrical Engineering',
      institution: 'Columbia University',
      description: 'Although my degree is in electrical engineering, I took mostly computer science classes in grad school, with an emphasis on machine learning and natural language processing.',
      awarded: 2013
    },
    {
      degree: 'B.S., Physics',
      institution: 'Yale University',
      description: 'I studied Physics and worked in an Atomic physics lab. During my two years running experiments, I earned recognition on a paper, got shot in the eye with a laser, and created a hovering ball of lightning.',
      awarded: '2006'
    }
  ]
}

export default experienceContent;
